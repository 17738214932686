.inputsList {
  .box {

    &:not(:last-child) {
      margin-bottom: @gutter;
    }

    .box-input {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: @gutter;
      }
    }

    .box-add {
      margin-top: @gutter;
    }
  }
}
.author {
  .photo-uploader > .ant-upload {
    width: 280px;
    height: 280px;
  }
}
.administration {
  margin: 0 @gutter;
  padding: @gutter 0;

  .admHeading {
    padding: 30px 0;

    h1 {
      margin-bottom: 0;
    }
  }
  .admRight {
    margin-bottom: @gutter * 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.administration {
  img {
    max-width: 300px;
  }
}
.video {
  max-width: 100%;
  width: 600px;
}
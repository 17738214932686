.articles {
  .visibility {
    display: flex;
    align-items: flex-end;

    & > * {
      margin-bottom: 0;
    }
  }
}
.forImage {
  position: relative;

  .delete {
    z-index: 1001;
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@import '~antd/dist/antd.less';
@import "./less/header";
@import "./less/auth";
@import "./less/user";
@import "./less/articles";
@import "./less/author";
@import "./less/visual";

@gutter: 15px;
@primary-color: #275498;@font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
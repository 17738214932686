.auth {
  height: 100vh;
  margin: 0 @gutter;
  text-align: center;
  .login {
    margin-top: -118px;
    @media(max-height: 500px){
      margin: 0;
    }

    form {
      & > *:not(:last-child) {
        margin-bottom: @gutter;
      }
    }

    .logo {
      margin-bottom: @gutter * 2;

      img {
        max-width: 180px;
      }
    }
  }
}
.header {
  padding: 15px 15px 0;
  background: #FAFAFA;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  .ant-menu {
    background: transparent !important;
    margin-left: -15px;
  }

  .logo {
    padding: 15px 0;

    img {
      max-width: 150px;
    }
  }
}
.navigation {

}
.buttons {
  position: absolute;
  background: #ebecec;
  padding: 6px 8px 8px 8px;
  border-radius: 6px;
  z-index: 2;
}
.visual {
  font-size: 16px;


  .new {
    width: 100%;
    margin-top: 30px;
    border: none;
  }
  .new-block {
    width: 100%;
    outline:none;
    margin-top: 10px;
    padding: 15px;

    &:focus {
      border: 2px solid #e1e1e1;
      background: #ebecec;
    }
  }
  .activeText {
    position: relative;
    margin: 20px 0;
    width: 100%;
    outline:none;
  }
  [placeholder]:empty::before {
    content: attr(placeholder);
    color: #555;
  }

  [placeholder]:empty:focus::before {
    content: "";
  }

  .image {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;

    img {
      width: 100%;
      max-width: 250px;
    }
  }
  .link {
    position: relative;
    display: block;
    padding: 20px 0;
  }
  .delete {
    position: absolute;
    top: 10px;
    right: -50px;
  }
  .top {
    position: absolute;
    top: 10px;
    right: -90px;
  }
  .bottom {
    position: absolute;
    top: 10px;
    right: -130px;
  }
}
.hidden-data {
  position: relative;
  padding-left: 50px;
  background: #ebecec;
}